<template>
    <div>
        <Card class="mb-5" v-if="pageNoWork">
            <template #title>
                <Toolbar>
                    <template #start>
                        <back-button />
                                <h3 class="ml-2">
                            {{ $t('edit_players_ava') }}
                        </h3>
                    </template>
                </Toolbar>
            </template>
            <template #content>
                <no-work-page 
                @refresh-request="getAvatar" 
                :loadingFlag="loadingFlag"
                textError="just_error"
                />
            </template>
        </Card>
        <Card class="mb-5" v-else>
            <template #title>
                <Toolbar>
                    <template #start>
                        <back-button />
                                <h3 class="ml-2">
                            {{ $t('edit_players_ava') }}
                        </h3>
                    </template>
                    <template #end>
                        <Button :label="$t('edit')" icon="pi pi-save" class="p-button-sm p-button-success" 
                        :loading="loadingFlag"
                        :disabled="isDisabledSave || loadingFlag"
                        @click="toEditAva" />
                    </template>
                </Toolbar>
            </template>
            <template #content>
                            <div class="rebuild-ava">
                                <div class="rebuild-ava__wrapper">
                                    <!-- <img :src="editAva.preview" :alt="editAva.name"/> -->
                                    <div class="rebuild-ava__content">
                                        <div class="rebuild-ava__item">
                                            <Skeleton size="5rem" v-if="loadingFlag"></Skeleton>
                                            <button type="button" class="rebuild-ava__image" @click="modalImages = true"
                                            v-else
                                            >
                                                <img ref="imagePreview"
                                                   :src="getImageAvatar(holdMainImagePreview, 'preview')"
                                                    alt="preview" />
                                                    <!-- {{ editAva.size }} -->
                                                    <span class="image-change">
                                                        <v-icon color="var(--surface-0)">mdi-camera</v-icon>
                                                    </span>
                                            </button>
                                            <div class="rebuild-ava__text">
                                                {{$t('each_ava')}}
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="rebuild-ava__content">
                                    <div class="rebuild-ava__item">
                                        <InputText type="text" v-model="editAva.name" class="p-inputtext-sm"
                                        :placeholder="$t('name')" />
                                    </div>
                                    <div class="rebuild-ava__item">
                                        <InputText type="text" v-model="editAva.descr" class="p-inputtext-sm"
                                        :placeholder="$t('description')" />
                                    </div>
                                    </div> -->
                                    <div class="rebuild-ava__actions">
                                        <Skeleton width="4rem" height="2rem" v-if="loadingFlag"></Skeleton>
                                        <div class="field-radiobutton _no-icon d-flex align-center" v-else>
                                            <Checkbox id="type" name="is_default" 
                                                @change="changeIsStarted(editAva)" 
                                                v-model="editAva.is_default"
                                                :binary="true"
                                                :disabled="editAva.is_default"
                                                />
                                            <label for="start_ava" class="ml-1">
                                                {{$t('started_ava')}}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Dialog header="Header" class="header-modal" :visible.sync="modalImages" :containerStyle="{'max-width': '350px'}" :modal="true">
                                <template #header>
                                    <div class="header-modal__wrapper">
                                        <div class="header-modal__item">
                                            <div class="header-modal__logo">
                                                <img src="@/assets/images/ADMIN_LOGO.png" width="75" alt="logo">
                                            </div>
                                        </div>
                                        <div class="header-modal__item">
                                            <h3 class="header-modal__title">
                                                {{ $t('edit_players_ava') }}
                                            </h3>
                                        </div>
                                    </div>
                                </template>
                                        <div class="header-content">
                                            <div class="text-left">
                                                <h3>
                                                    {{ $t('ava') }}
                                                </h3>
                                            </div>
                                            <div class="text-left" v-html="$t('ava_descr')">
                                            </div>
                                        </div>
                                        <Divider class="my-3"/>
                                        <span v-if="noImageError" class="c-red d-flex justify-start font-weight-bold mb-3">
                                            {{ $t('select_at_least_one') }}
                                        </span>
                                        <div class="header-content">
                                            <div class="header-content-images">
                                                <div class="header-content-images__item">
                                                <div class="header-content-images__content header-content-images__content_big">
                                                    <div class="header-content-images__image" v-if="imagePreviews.image">
                                                        <img
                                                       :src="getImageAvatar(imagePreviews.image, 'image')"
                                                        alt="image" />
                                                        <div class="delete-close" @click="deleteImage('image')">
                                                            <v-icon color="var(--surface-100)">mdi-close</v-icon>
                                                        </div>
                                                    </div>
                                                    <div @click="triggerImageInput" class="header-content-images__square" v-else>
                                                        <input type="file" ref="imagePreview" accept="image/png, image/jpeg, image/svg+xml" 
                                                            style="display: none"
                                                            id="trigger-image-id" @change="uploadImage($event, 'image')">
                                                        <div class="no-photo-plus">
                                                            <v-icon color="var(--surface-900)">mdi-plus</v-icon>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="header-content-images__text">
                                                    (1)
                                                </div>
                                            </div>
                                                <div class="header-content-images__item">
                                                    <div class="header-content-images__content header-content-images__content_small">
                                                        <div class="header-content-images__image" v-if="imagePreviews.preview">
                                                        <img
                                                        :src="getImageAvatar(imagePreviews.preview, 'preview')"
                                                    alt="preview" />
                                                    <div class="delete-close" @click="deleteImage('preview')">
                                                        <v-icon color="var(--surface-100)">mdi-close</v-icon>
                                                    </div>
                                                </div>
                                                        <div @click="triggerPreviewInput" class="header-content-images__square" v-else>
                                                            <input type="file" ref="imagePreview" accept="image/png, image/jpeg, image/svg+xml" 
                                                            style="display: none"
                                                            id="trigger-preview-id" @change="uploadImage($event, 'preview')">
                                                            <div class="no-photo-plus">
                                                                <v-icon color="var(--surface-900)">mdi-plus</v-icon>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="header-content-images__text">
                                                        (2)
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                   <template #footer>
                                    <div class="d-flex align-center">
                                        <Button :label="$t('confirm')" icon="pi pi-save" class="p-button-sm p-button-success mt-2 mr-0" 
                                        style="width: 100%"
                                    @click="confirmImages" />
                                    </div>
                                   </template>     
                            </Dialog>
            </template>
        </Card>
        
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
name: 'EditPlayerAvatars',
data() {
    return {
        modalImages: false,
        pageNoWork: false,
        noImageError: false,
        loadingFlag: false,
        holdMainImagePreview: null,
        noNewImageError: false,
        imagePreviews:{
            image: null,
            image_file: null,
            preview: null,
            preview_file: null,
        },
        editAva: {
            is_default: false,
            id: null,
            image: null,
            preview: null,
        },
    }
},
watch: {
    noNewImageError(newVal){
        if (newVal) {
            this.modalImages = true;
            this.$toast.add({ severity: 'info', summary: this.$t("add_photo_to_save"), life: 10000, });
            this.noNewImageError = false;
        }
    }
},
computed: {
    ...mapGetters({
    }),
    isDisabledSave(){
        return false;
    },
    
},
async mounted() {
    await this.getAvatar();
},
methods: {
    getImageAvatar(url, type){
        if (!url) {
            return;
        }
            if (url.startsWith('blob:')) {
                return url;
            } else {
                return `https://minio.takemybet.pro/avatars/${url}`
            //     if (type === 'preview') {
            // } else{
            //     return `https://minio.takemybet.pro/avatars/${url}`
            // }
        } 
    },
    confirmImages(){
        this.noImageError = false;
        if (!this.imagePreviews.preview && !this.imagePreviews.image) {
            this.noImageError = true;
            return;
        }
        if (!this.imagePreviews.preview) {
            this.imagePreviews.preview = this.imagePreviews.image;
            this.imagePreviews.preview_file = this.imagePreviews.image_file;
        } else if (!this.imagePreviews.image) {
            this.imagePreviews.image = this.imagePreviews.preview;
            this.imagePreviews.image_file = this.imagePreviews.preview_file;
        }
        this.modalImages = false;
    },
    async getAvatar(){
        this.loadingFlag = true;
        const id = this.getIdFromUrl();
        try {
        this.editAva = await this.$store.dispatch('playerAvatars/getAvatarById', id);
        const holdEditAva = JSON.parse(JSON.stringify(this.editAva.preview));
        this.holdMainImagePreview = holdEditAva;
        this.imagePreviews.preview = this.editAva.preview;
        this.imagePreviews.image = this.editAva.image;
        
        this.pageNoWork = false;
    } catch (error) {
        this.pageNoWork = true;
        this.$toast.add({ severity: 'error', summary: this.$t("just_error"), life: 4000, });
    } finally{
        this.loadingFlag = false;
    }
    },
    getIdFromUrl() {
              const url = window.location.pathname;
              const parts = url.split('/'); 
              return parts[parts.length - 1];
            },
    async getImageInfo() {
        for (const ava of this.avatars){
        const img = new Image();
        const avaPreview = ava.preview;
            console.log('ava', ava);
            img.src = ava.preview;
             await new Promise((resolve, reject) => {
               img.onload = resolve;
               img.onerror = reject;
             });
             console.log('img', img.weight);
            const size = `${img.width}x${img.height}`; 
            console.log('size', size);
            const weight = `${Math.round(img.src.length / 1024)} KB`; 
                ava.size = size;
                ava.weight = weight;
    }
          },
          triggerImageInput(){
            document.getElementById('trigger-image-id').click();
          }, 
          triggerPreviewInput(){
            document.getElementById('trigger-preview-id').click();
          },
          uploadImage(event, type){
            const file = event.target.files[0];
            if (file && file.size <= 500000) {
                    if (type === 'preview') {
                  const imageUrl = URL.createObjectURL(file);
                  this.imagePreviews.preview = imageUrl;
                  this.imagePreviews.preview_file = file;
            } else if (type === 'image') {
                  const imageUrl = URL.createObjectURL(file);
                  this.imagePreviews.image = imageUrl;
                  this.imagePreviews.image_file = file;
            } 
        } else{
                this.$toast.add({
                severity: 'error', 
                summary: this.$t('upload_error'), 
                detail: this.$t('upload_less_image'), 
                life: 8000
            });
            this.deleteImage(type);
            }
          },
    async toEditAva(){
        const edit = this.imagePreviews;
        if (!edit.image_file && !edit.preview_file) {
            this.noNewImageError = true;
            return;
        }
        this.loadingFlag = true
        try {
            const form = new FormData();
            const id = this.editAva.id
            form.append('id', id);
            if (edit.image_file) {
                form.append('image', edit.image_file);
            }
            if (edit.preview_file) {
                form.append('preview', edit.preview_file);
            }
            await this.$store.dispatch('playerAvatars/editAvatar', {
                data: form,
                id: id,
            });
            this.$toast.add({ severity: 'success', summary: this.$t("ava_edited"), life: 4000, });
            await this.$store.dispatch('playerAvatars/getAvatars');
            await this.$router.push('/avatars/view');
        } catch (error) {
            this.$toast.add({ severity: 'error', summary: this.$t("just_error"), life: 4000, });
        } finally{

            this.loadingFlag = false
        }
    },
    deleteImage(type){
        if (type === 'image') {
            URL.revokeObjectURL(this.imagePreviews.image);
            this.editAva.image = null;
            this.imagePreviews.image = null;
            this.imagePreviews.image_file = null;
        } else if(type === 'preview'){
            URL.revokeObjectURL(this.imagePreviews.preview);
            this.imagePreviews.preview = null;
            this.imagePreviews.preview_file = null;
        };
    },
},
beforeRouteEnter(to, from, next) {
        next(vm => {

                 vm.$store.commit('setPageName', 'players_ava')
        });
},
}
</script>

<style lang="scss" scoped>
</style>